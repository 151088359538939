import React from "react";

export const ThumbnailImage: React.FC<{
  src: string;
  alt: string;
  variant?: "default" | "thin";
}> = React.memo(({ src, alt, variant = "default" }) => (
  <div
    className={`
        relative
        ${variant === "thin" ? "w-24 h-24" : "w-full h-full md:w-48 h-48"}
        overflow-hidden
        bg-gray-200
      `}
  >
    <img
      src={src}
      alt={alt}
      className={`
            absolute
            inset-0
            w-full
            h-full
            object-cover
          `}
      loading="lazy"
    />
  </div>
));
