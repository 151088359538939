import { Switch } from "@headlessui/react";
import clsx from "clsx";
import ReactGA from "react-ga4";

export interface ToggleProperties {
  label?: string;
  caption?: string;
  value?: boolean;
  onChange: (value: boolean) => void;
  labelChildren?: JSX.Element;
  isDisabled?: boolean;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
}

export const Toggle = (properties: ToggleProperties): JSX.Element => {
  const {
    label,
    caption,
    value,
    onChange,
    labelChildren,
    isDisabled,
    eventCategory,
    eventAction,
    eventLabel,
  } = properties;

  const handleSwitchChange = (updatedValue: boolean) => {
    if (!isDisabled) {
      // track button event in google analytics
      if (eventAction && eventCategory) {
        ReactGA.event({
          category: eventCategory.toLocaleLowerCase(),
          action: `${eventAction.toLocaleLowerCase()}_click`,
          label: eventLabel,
        });
      }

      onChange(updatedValue);
    }
  };

  return (
    <Switch.Group as="div" className="flex w-full items-center justify-between">
      <span className="flex-grow flex flex-col">
        <Switch.Label as="span" className="flex-grow flex flex-col" passive>
          {!labelChildren && (
            <div>
              <label className="block font-medium">{label}</label>

              {caption && <p className="text-gray-600 text-sm">{caption}</p>}
            </div>
          )}

          {labelChildren && <span>{labelChildren}</span>}
        </Switch.Label>
      </span>
      <Switch
        checked={value}
        onChange={handleSwitchChange}
        disabled={isDisabled}
        className={clsx(
          "relative inline-flex flex-shrink-0 h-6 w-11 ml-4 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-toolkitTurquoise",
          value ? "bg-toolkitTurquoise" : "bg-gray-200",
          isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            value ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
          )}
        />
      </Switch>
    </Switch.Group>
  );
};
