import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  DocumentIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Badge } from "../badges/Badge";
import { ProgressBar } from "../platform/fields/ProgressBar";
import { Upload } from "../../types/Upload";
import { getUploadThumbnails } from "../../util/ApiIntegration";
import { Loading } from "../platform/Loading";
import { useQuery } from "@tanstack/react-query";
import {
  formatDateToShortDisplay,
  formatSecondsDuration,
} from "../../util/helpers";
import { ThumbnailImage } from "../platform/ThumbnailImage";
import { FileIcon } from "../platform/FileIcon";

interface UploadCardProperties {
  upload: Upload;
  linkTitle?: boolean;
  variant?: "default" | "thin";
  onSelect?: () => void;
}

export function UploadCard({
  upload,
  linkTitle,
  variant = "default",
  onSelect,
}: UploadCardProperties): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const cardReference = useRef<HTMLDivElement>(null);

  const [duration, setDuration] = useState<string | undefined>();
  const [sizeInMB, setSizeInMB] = useState<string | undefined>();
  const [lastUpdated, setLastUpdated] = useState<string | undefined>();

  const { data: thumbnail, isLoading: isLoadingThumbnail } = useQuery({
    queryKey: ["thumbnail", upload.id],
    queryFn: async () => {
      if (!upload.id) return;
      const thumbnails = await getUploadThumbnails(upload.id, 1);
      return thumbnails[0] ?? "";
    },
    enabled: !!upload.id && upload.state === "complete",
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: 1000 * 60 * 60,
  });

  useEffect(() => {
    if (!upload) return;

    if (upload.byteSize && upload.byteSize > 0) {
      setSizeInMB(
        " • " + ((upload.byteSize || 0) / (1024 * 1024)).toFixed(2) + " MB",
      );
    } else {
      setSizeInMB("");
    }

    if (upload.durationSeconds && upload.durationSeconds > 0) {
      setDuration(" • " + formatSecondsDuration(upload.durationSeconds));
    } else {
      setDuration("");
    }

    if (
      upload.lastUpdated &&
      upload.lastUpdated.toString() !== "0001-01-01T00:00:00"
    ) {
      const formattedDate = formatDateToShortDisplay(
        new Date(upload.lastUpdated),
      );
      setLastUpdated(" • " + formattedDate);
    } else {
      setLastUpdated("");
    }
  }, [upload]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 },
    );

    if (cardReference.current) {
      observer.observe(cardReference.current);
    }

    return () => {
      if (cardReference.current) {
        observer.unobserve(cardReference.current);
      }
    };
  }, []);

  const renderThumbnail = () => {
    const containerClasses =
      variant === "thin"
        ? "w-24 h-24 flex-shrink-0"
        : "h-48 w-full md:w-48 flex-shrink-0";

    return (
      <div
        className={`
        ${containerClasses}
        bg-gray-200
        flex
        items-center
        justify-center
        overflow-hidden
      `}
      >
        {upload.state === "complete" ? (
          isLoadingThumbnail ? (
            <Loading />
          ) : thumbnail && isVisible ? (
            <ThumbnailImage
              src={thumbnail}
              alt={upload.title || ""}
              variant={variant}
            />
          ) : (
            <FileIcon
              fileExtension={upload.fileExtension || ""}
              className={variant === "thin" ? "h-8 w-8" : "h-12 w-12"}
            />
          )
        ) : upload.state === "broken" ? (
          <div className="text-center">
            <ExclamationTriangleIcon
              className={`
                ${variant === "thin" ? "h-8 w-8" : "h-12 w-12"}
                text-toolkitPink mx-auto
              `}
            />
            <p className="text-sm text-toolkitPink mt-2">Error</p>
          </div>
        ) : (
          <div className="text-center">
            <DocumentIcon
              className={`
                ${variant === "thin" ? "h-8 w-8" : "h-12 w-12"}
                text-gray-400 mx-auto
              `}
            />
            <p className="text-sm text-gray-500 mt-2">Processing...</p>
          </div>
        )}
      </div>
    );
  };

  const cardClasses =
    variant === "thin"
      ? "bg-white rounded-lg shadow-sm overflow-hidden mb-2 hover:bg-gray-50 transition-colors cursor-pointer"
      : "bg-white rounded-xl shadow-md overflow-hidden mb-4";

  const contentClasses =
    variant === "thin" ? "flex items-center p-2" : "md:flex";

  return (
    <div
      id={upload.id}
      key={upload.id}
      ref={cardReference}
      className={cardClasses}
      onClick={onSelect}
    >
      <div className={contentClasses}>
        <div
          className={variant === "thin" ? "flex-shrink-0" : "md:flex-shrink-0"}
        >
          {renderThumbnail()}
        </div>
        <div
          className={
            variant === "thin" ? "ml-4 flex-grow min-w-0" : "p-6 flex-grow"
          }
        >
          {variant === "default" && (
            <div className="flex items-baseline mb-2">
              {upload.enabled && <Badge text="Enabled" className="mr-2" />}
              {upload.isbn && <Badge text={upload.isbn} />}
              {upload.doi && <Badge text={upload.doi} />}
              <div className="ml-2 text-gray-500 text-xs uppercase">
                {upload.fileExtension} {sizeInMB} {lastUpdated} {duration}
              </div>
            </div>
          )}

          {linkTitle ? (
            <Link
              to={`/uploads/editor/${upload.id}`}
              className={`block font-bold text-black hover:underline ${variant === "thin" ? "text-base truncate" : "text-xl mb-2"}`}
            >
              {upload.title || "No Title"}
            </Link>
          ) : (
            <p
              className={`block font-bold text-black cursor-not-allowed ${variant === "thin" ? "text-base truncate" : "text-xl mb-2"}`}
              title="Not ready yet!"
            >
              {upload.title || "No Title"}
            </p>
          )}

          {variant === "default" && upload.reference && (
            <p className="text-gray-500 text-xs">{upload.reference}</p>
          )}

          {upload.description && (
            <p
              className={`text-sm ${variant === "thin" ? "text-gray-500 truncate" : "mt-2 mb-2"}`}
            >
              {variant === "thin"
                ? upload.description
                : upload.description.length > 220
                  ? `${upload.description.slice(0, 220)}...`
                  : upload.description}
            </p>
          )}

          {variant === "default" && upload.state !== "complete" && (
            <div className="mt-4">
              <p className="text-sm mb-2">{upload.message}</p>
              {upload.state !== "broken" && (
                <ProgressBar
                  progress={upload.progressPercent || 0}
                  max={100}
                  paused={false}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
