import React, { useCallback, forwardRef } from "react";
import { Menu, MenuItems, Transition } from "@headlessui/react";
import { NavLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Profile } from "../../../types/platform/Profile";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Gravatar from "../../platform/Gravatar";

interface ProfileMenuProperties {
  profile: Profile;
  isDropDown: boolean;
  onClose?: () => void;
}

interface NavigationItem {
  path: string;
  label: string;
}

const navigationItems: NavigationItem[] = [
  { path: "/outcomes", label: "Outcomes" },
  { path: "/uploads", label: "Uploads" },
  { path: "/sharing", label: "Sharing" },
  { path: "/profile", label: "Profile" },
];

const mobileLinkStyle =
  "bg-opacity-0 text-md text-toolkitBlack block font-medium rounded-md bg-white px-3 py-2 hover:bg-opacity-10";
const desktopLinkStyle =
  "block px-2 py-2 rounded-md hover:bg-toolkitGrey transition ease-in-out duration-150";

const ProfileAvatar = forwardRef<HTMLDivElement, { profile: Profile }>(
  ({ profile }, reference) => (
    <div
      ref={reference}
      className="bg-white text-sm rounded-full ring-toolkitGrey ring-2 ring-offset-1 hover:ring-toolkitTurquoise focus:ring-toolkitTurquoise"
    >
      <span className="sr-only">Open profile menu</span>
      <Gravatar
        className="h-10 w-10 rounded-full"
        hash={profile.id}
        title={profile?.name || profile?.email || profile?.id}
      />
    </div>
  ),
);

ProfileAvatar.displayName = "ProfileAvatar";

const ProfileInfo: React.FC<{ profile: Profile }> = React.memo(
  ({ profile }) => (
    <div className="flex items-center px-5">
      <div className="flex-shrink-0">
        <Gravatar
          className="h-10 w-10 rounded-full"
          hash={profile.id}
          title={profile?.name || profile?.email || profile?.id}
        />
      </div>
      <div className="ml-3">
        <div className="text-base font-medium text-toolkitBlack">
          {profile.name}
        </div>
        <div className="text-sm font-medium text-toolkitBlack">
          {profile.email}
        </div>
      </div>
    </div>
  ),
);

ProfileInfo.displayName = "ProfileInfo";

const MenuItem = forwardRef<
  HTMLAnchorElement,
  {
    item: NavigationItem;
    onItemClick: (path: string) => void;
    active?: boolean;
  }
>(({ item, onItemClick, active }, reference) => (
  <Menu.Item>
    {({ active: menuActive }) => (
      <NavLink
        ref={reference}
        to={item.path}
        className={clsx(
          desktopLinkStyle,
          active || menuActive ? "bg-toolkitGrey" : "",
        )}
        onClick={() => onItemClick(item.path)}
      >
        <p className="text-md font-medium">{item.label}</p>
      </NavLink>
    )}
  </Menu.Item>
));

MenuItem.displayName = "MenuItem";

const MenuDivider: React.FC = () => <hr className="my-2" />;

const LogoutButton = forwardRef<
  HTMLButtonElement,
  { onLogout: () => void; isMobile?: boolean }
>(({ onLogout, isMobile }, reference) => (
  <button
    ref={reference}
    onClick={onLogout}
    className={clsx(
      isMobile
        ? mobileLinkStyle
        : "block w-full text-left px-2 py-2 rounded-md hover:bg-toolkitGrey transition ease-in-out duration-150",
    )}
  >
    <p className="text-md font-medium">Logout</p>
  </button>
));

LogoutButton.displayName = "LogoutButton";

export const ProfileMenu: React.FC<ProfileMenuProperties> = React.memo(
  ({ profile, isDropDown, onClose }) => {
    const navigate = useNavigate();

    const handleItemClick = useCallback(
      (path: string) => {
        onClose?.();
        navigate(path);
      },
      [onClose, navigate],
    );

    const renderDropdownMenu = () => (
      <div className="hidden md:block z-20">
        <div className="flex items-center">
          <Menu as="div" className="ml-4 relative">
            {({ open }) => (
              <>
                <Menu.Button className="flex items-center">
                  <ProfileAvatar profile={profile} />
                  <ChevronDownIcon
                    className={clsx(
                      "hidden flex-shrink-0 ml-2 h-5 w-5 text-toolkitBlack md:block",
                      open ? "transform rotate-180" : "",
                    )}
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="origin-top-right absolute right-0 mt-2 w-60 py-4 px-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {navigationItems.map(item => (
                      <MenuItem
                        key={item.path}
                        item={item}
                        onItemClick={handleItemClick}
                      />
                    ))}
                    <MenuDivider />
                    <LogoutButton onLogout={() => handleItemClick("/logout")} />
                  </MenuItems>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    );

    const renderMobileMenu = () => (
      <div className="pt-4 pb-3 border-t border-white">
        <ProfileInfo profile={profile} />
        <div className="mt-3 px-2 space-y-1">
          {navigationItems.map(item => (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) =>
                clsx(mobileLinkStyle, isActive ? "underline" : "")
              }
              onClick={() => onClose?.()}
            >
              {item.label}
            </NavLink>
          ))}
          <LogoutButton onLogout={() => handleItemClick("/logout")} isMobile />
        </div>
      </div>
    );

    return isDropDown ? renderDropdownMenu() : renderMobileMenu();
  },
);

ProfileMenu.displayName = "ProfileMenu";
