import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export const generateBreadcrumb = (
  breadcrumbs: Breadcrumb[],
  label: string,
  to: string,
  id?: string,
): Breadcrumb[] => {
  // seed the crumb
  if (!breadcrumbs || breadcrumbs.length === 0) {
    return [{ label, to, current: true }];
  }

  // set previous as not current
  const crumbs = breadcrumbs;
  crumbs[breadcrumbs.length - 1].current = false;

  // push latest
  crumbs.push({
    id,
    label,
    to,
    current: true,
  });

  return crumbs;
};

export interface Breadcrumb {
  label: string;
  to: string;
  id?: string;
  current: boolean;
}

interface BreadcrumbsProperties {
  basePath: string;
  breadcrumbs: Breadcrumb[];
  classNames?: string;
}

export function Breadcrumbs(properties: BreadcrumbsProperties): JSX.Element {
  const { basePath, breadcrumbs, classNames } = properties;

  return (
    <div className={`${classNames} border-t border-b`}>
      <div className="max-w-7xl relative mx-auto py-6 xs:px-4 sm:px-8">
        <nav aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <Link
                  to={basePath}
                  id={basePath}
                  className="hover:text-toolkitTurquoise"
                >
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </li>

            {breadcrumbs.map(crumb => (
              <li key={crumb.label}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 "
                    aria-hidden="true"
                  />

                  <Link
                    id={crumb.to}
                    to={crumb.to}
                    className="ml-4 text-sm font-medium capitalize  hover:text-toolkitTurquoise"
                  >
                    {crumb.label.length > 100
                      ? `${crumb.label.slice(0, 100)}...`
                      : crumb.label}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}
