import React, { useState, useEffect } from "react";

interface GravatarProperties {
  hash?: string;
  title?: string;
  className?: string;
}

const Gravatar: React.FC<GravatarProperties> = ({ hash, title, className }) => {
  const [error, setError] = useState(false);
  const [initials, setInitials] = useState("NA");

  const url = `https://www.gravatar.com/avatar/${hash}.png?d=404`;

  // Get initials from title or default to 'NA'
  useEffect(() => {
    if (title) {
      setInitials(
        title
          .split(" ")
          .map(n => n[0])
          .join(""),
      );
    }
  }, [title]);

  const handleError = () => setError(true);

  return error || !hash ? (
    <div
      className={`inline-flex items-center justify-center rounded-full bg-gray-300 
                  text-white uppercase ${className}`}
    >
      {initials}
    </div>
  ) : (
    <img className={className} src={url} alt={title} onError={handleError} />
  );
};

export default Gravatar;
