import React, { useEffect, useState } from "react";

interface CountDownTimerProperties {
  onTimeUp: () => void;
  dateTime: string | undefined | Date;
}

function getTimeInSecs(dateTime: string | undefined | Date): number {
  const nowDateTime = new Date();
  const endDateTime =
    dateTime instanceof Date ? dateTime : new Date(dateTime || "");

  const diff = Math.abs(endDateTime.valueOf() - nowDateTime.valueOf()) / 1000;

  // If the current time has already passed the end date time, return 0
  return diff < 0 ? 0 : diff;
}

export function CountDownTimer(
  properties: CountDownTimerProperties,
): JSX.Element {
  const { dateTime, onTimeUp } = properties;

  const [timeInSecs, setTimeInSecs] = useState(getTimeInSecs(dateTime));

  useEffect(() => {
    // Check if the time has already ended
    if (timeInSecs < 1) {
      onTimeUp();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeInSecs(currentTimeInSecs => {
        const newTimeInSecs = currentTimeInSecs - 1;

        if (newTimeInSecs <= 0) {
          clearInterval(intervalId);
          onTimeUp();

          return 0;
        }

        return newTimeInSecs;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeInSecs]);

  const hours = Math.floor(timeInSecs / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeInSecs % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(timeInSecs % 60)
    .toString()
    .padStart(2, "0");

  return (
    <span className="justify-center items-center font-medium text-base">
      <span>{hours}</span>
      <span>:</span>
      <span>{minutes}</span>
      <span>:</span>
      <span>{seconds}</span>
    </span>
  );
}
