import {
  DocumentIcon,
  VideoCameraIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import React from "react";

export const FileIcon: React.FC<{ fileExtension: string; className?: string }> =
  React.memo(({ fileExtension, className = "mx-auto h-12 w-12" }) => {
    switch (fileExtension?.toUpperCase()) {
      case "PDF": {
        return <DocumentIcon className={`${className} text-gray-400`} />;
      }
      case "MP4":
      case "MOV": {
        return <VideoCameraIcon className={`${className} text-gray-400`} />;
      }
      case "JPG":
      case "PNG": {
        return <PhotoIcon className={`${className} text-gray-400`} />;
      }
      default: {
        return <DocumentIcon className={`${className} text-gray-400`} />;
      }
    }
  });
